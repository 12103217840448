@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media only screen and (max-width: 767px) {
  html {
    font-size: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  html {
    font-size: 4px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1023px) {
  html {
    font-size: 5px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1430px) {
  html {
    font-size: 6px;
  }
}

@media only screen and (min-width: 1431px) and (max-width: 1450px) {
  html {
    font-size: 6.15px;
  }
}

@media only screen and (min-width: 1451px) and (max-width: 1599px) {
  html {
    font-size: 7px;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  html {
    font-size: 8px;
  }
}

@media only screen and (min-width: 1920px) and (max-width: 2559px) {
  html {
    font-size: 8.5px;
  }
}

@media only screen and (min-width: 2560px) and (max-width: 3599px) {
  html {
    font-size: 10px;
  }
}

@media only screen and (min-width: 3600px) {
  html {
    font-size: 27px;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

:root {
  --table-height: 80%;
}
